.hero {
  background-image: url("./hero3.jpg");
  background-color: #0000008a;
  height: 500px;
  background-size: cover;
  background-position-x: right;
}

.hero-text {
  justify-content: center;
  font-size: 50px;
  text-align: left;
  position: absolute;
  top: 200px;
  left: 60px;
  /* background: #ffffff8f; */
  letter-spacing: 0px;
  line-height: 69px;
  color: #6f6067;
  /* text-transform: uppercase; */
  font-style: italic;
}

@media only screen and (max-width: 900px) {
  .hero {
    background-blend-mode: darken;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .hero-text {
    font-size: 40px;
    color: white;
    position: initial;
    line-height: 45px;
    margin-bottom: 50px;
  }
}

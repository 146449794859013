.navbar {
  // width: 100vw;
  height: 70px;
  display: flex;
  //   justify-content: center;
  align-items: center;
  box-shadow: 0 2px 8px 3px #00000038;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 0px;
  position: sticky;
  top: 0;
  background: white;
  border-radius: 0px;
  z-index: 999;
  .left {
    min-width: 200px;
    display: flex;
    flex: 1;
    align-items: flex-start;
    margin: 0 20px;
  }

  .right {
    margin: 0 20px;
    display: flex;

    .nav-item {
      text-decoration: none;
      cursor: pointer;
      margin: 0 10px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      height: 70px;
      &:hover {
        background-color: rgb(222, 222, 222);
        text-decoration: none;
      }
      &:active {
        background-color: rgb(208, 205, 205);
      }
    }
  }

  .menu-icon {
    width: 24px;
  }

  .menu-text {
    background: #bfbfbf66;
    padding: 8px 24px;
    border-radius: 8px;
  }
}

@media only screen and (max-width: 1090px) {
  .navbar {
    padding-left: 0;
    padding-right: 0;

    .left {
      min-width: 0px;
    }
  }

  .dropdown {
    position: fixed;
    background: white;
    width: 100vw;
    top: 70px;
    z-index: 8;
    box-shadow: 0px 20px 14px 4px rgb(0 0 0 / 46%);
    .nav-item {
      // margin: 10px 0;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:active {
        background-color: rgb(227, 227, 227);
      }
    }
  }
}

.contact-us {
  padding: 20px;

  width: 100vw;
  background-color: #242764;
  padding-bottom: 80px;
  color: #d6d6d6;

  .message {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 30px;
  }

  p {
    margin: 0;
  }

  .content {
    margin-top: 35px;
    max-width: 800px;
    margin: auto;
    text-align: left;
    .contact-form {
      margin-bottom: 30px;
    }

    .details {
      .title {
        font-size: 22px;
        font-weight: 700;
      }

      .road,
      .district,
      .country,
      .zip,
      .phone {
        font-size: 18px;
      }
    }

    textarea {
      height: 150px;
    }
  }
}

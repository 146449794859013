.team-section {
  background: #e1fffc;
  padding: 20px;
}
.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.contact-card {
  border: 1px solid;
  margin-bottom: 15px;
  padding: 15px;
  width: 370px;
  .name {
    font-size: 20px;
    font-weight: 500;
  }
  .role {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .contact-mode {
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .icon {
    width: 21px;
    margin-right: 6px;
  }
}

.trade-container {
  padding: 20px;
  margin: auto;
}

.product-card {
  margin-bottom: 20px;
  width: 100%;
  // box-shadow: 0px 4px 9px 0px #00000029;
  overflow: hidden;
  background: #fff4e8;
  .title {
    /* border: 1px solid; */
    /* width: 350px; */
    /* padding: 20px; */
    /* margin: 10px 20px; */
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 22%);
    cursor: pointer;

    background: #d1f5fa;

    display: flex;
    flex-direction: row;

    .title-text {
      flex: 1;
    }

    .icon {
      margin-right: 20px;
      width: 22px;
    }
  }

  .items {
    margin-top: 20px;
    text-align: start;
  }
}
